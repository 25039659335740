import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

// const AssignSchedules = () => import("@/components/Nodes/AssignSchedules");
const FAQ = () => import(/* webpackChunkName: "group-main" */"@/components/Help/FAQ");
const AssignNodes = () => import(/* webpackChunkName: "group-schedule" */"@/components/Nodes/AssignNodes");
const SchedulesInfo = () => import(/* webpackChunkName: "group-schedule" */"@/components/Schedule/SchedulesInfo");
const DaySchedule = () => import(/* webpackChunkName: "group-schedule" */"@/components/Schedule/DaySchedule");
const Schedule = () => import(/* webpackChunkName: "group-schedule" */'@/components/Schedule/Schedule.vue');
const NewSchedule = () => import(/* webpackChunkName: "group-schedule" */"@/components/Schedule/NewSchedule");
const DuskSchedule = () => import(/* webpackChunkName: "group-schedule" */"@/components/Schedule/DuskSchedule");
const Calendar = () => import(/* webpackChunkName: "group-schedule" */'@/components/Schedule/Calendar.vue');
const Map2 = () => import(/* webpackChunkName: "group-nodes" */"@/components/Map2");
const Nodes = () => import(/* webpackChunkName: "group-nodes" */"@/components/Nodes.vue");
const Zones = () => import(/* webpackChunkName: "group-nodes" */"@/components/Zones.vue");
const Login = () => import(/* webpackChunkName: "group-main" */"@/components/Standard/Login");
const Module2 = () => import(/* webpackChunkName: "group-nodes" */"@/components/NewModule/Module2.vue");
const Home = () => import(/* webpackChunkName: "group-main" */'@/components/Standard/Home.vue');
const NotFound = () => import(/* webpackChunkName: "group-main" */'@/components/Standard/NotFound.vue');

const routes = [
    {
        path: '/',
        name: "Map",
        component: Map2,
        props: true,
        meta:{
            requiresAuth: true,
            btnId: 1,
        }
    },
    {
        path: '/map',
        name: 'Map',
        component: Map2,
        props: true,
        meta: {
            requiresAuth: true,
            btnId: 1,
        }
    },
    {
        path: '/stations',
        name: "Stations",
        component: Nodes,
        meta: {
            requiresAuth: true,
            btnId: 4,
        }
    },
    {
        path: '/zones',
        name: 'Zones',
        component: Zones,
        meta: {
            btnId: 3,
            requiresAuth: true
        }
    },
    {
        path: '/firmware',
        name: 'Firmware Update',
    },
    {
        path: '/schedule',
        name: 'Schedule',
        component: Schedule,
        meta: {
            btnId: 5,
            requiresAuth: true
        },
        children: [
            {
                path: 'new',
                name: 'schedNew',
                component: NewSchedule,
                props: true
            },
            {
                path: 'info',
                name: 'schedInfo',
                component: SchedulesInfo,
            },
            {
                path: 'calendar',
                component: Calendar,
                children: [
                    {
                        path: 'overview',
                        name: 'schedOverview',
                        component: DaySchedule,
                        props: true
                    },
                    {
                        path: 'dusk',
                        name: 'schedDusk',
                        component: DuskSchedule,
                        props: true
                    }
                ],
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'assignNodes',
                name: 'assignNodes',
                component: AssignNodes,
                props: true
            },
        ],
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ
    },
    {
        path: '/notfound',
        name: "Not Found",
        component: NotFound
    },
    {
        path:'/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard/:ctzid',
        name: 'Dashboard',
        component: Module2,
        props: true,
        meta: {
            requiresAuth: true,
            btnId: 4,
        }
    },
    {
        path: '/?message=:message',
        name: 'Message',
        component: Home,
    },
    {
        path: '/:notFound(.*)',
        component: NotFound
    }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(async (to, _, next) =>{
    if(to.meta.btnId){
        const activeBtn = to.meta.btnId;
        console.log("Going to PAGE with id : ", activeBtn);
        store.dispatch('auth/setActiveBtn', activeBtn).then(() => next());
    } else next();
});

export default router
